<template>
	<section class="app-container corehumanConfig">
		<p>
			<strong>操作人：{{ user }}</strong
			><strong>操作时间：{{ updateTime }}</strong>
		</p>
		<div>
			<!--<el-button type="primary" icon="el-icon-edit-outline" size="medium" @click="$router.push({name:'applyInfo'})">申报核心人力</el-button>!-->
			<el-button
				type="primary"
				v-show="!edit"
				@click="edit = true"
				size="small"
				icon="el-icon-edit"
				v-if="permissionControlBtns(pageName, 'Edit')"
				>编辑</el-button
			>
			<el-button type="primary" v-show="edit" @click="commit" icon="el-icon-edit" size="small">保存</el-button>
		</div>
		<ul>
			<label style="font-weight: normal">申报等级</label>
			<li v-for="(level, i) in levels" :key="i">
				<el-input v-model="level.levelName" :disabled="!edit" @focus="levelName = level.levelName" @blur="saveConfig(level, i)"></el-input>
				<el-button type="danger" v-show="edit" icon="el-icon-delete" @click="deletelevel(level, i)"></el-button>
			</li>
		</ul>
		<el-button type="primary" icon="el-icon-plus" @click="addlevel" v-show="edit">新增申报等级</el-button>
	</section>
</template>

<script>
import { coreConfiglList, coreConfiglSave, coreConfiglDel } from '../../api/corehuman';

export default {
	data() {
		return {
			user: '张三',
			updateTime: '2020-3-29 11:30',
			edit: false,
			levels: [],
			levelName: ''
		};
	},
	computed: {
		pageName() {
			return this.$route.name;
		}
	},
	methods: {
		commit() {
			let status = false;

			this.$confirm('是否确定提交?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.levels.forEach((it) => {
					if (!status && !it.levelName) {
						this.$message.warning('检测到有配置项为空,请完善后再点击完成');
						status = true;
					}
				});
				this.edit = status;
				if (!this.edit) {
					this.getList();
				}
			});
		},
		deletelevel(level, index) {
			if (level.id) {
				this.$confirm('是否确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					coreConfiglDel({ id: level.id }).then((res) => {
						if (res._responseStatusCode == 0) {
							this.levels.splice(index, 1);
						}
					});
				});
			} else {
				this.levels.splice(index, 1);
			}
		},
		getList() {
			coreConfiglList({}).then((res) => {
				if (res._responseStatusCode == 0) {
					this.user = res.staffName;
					this.updateTime = res.updateTime;
					this.levels = res.configListDtos || [];
				}
			});
		},
		addlevel() {
			if (this.levels.length > 0 && !this.levels[this.levels.length - 1].levelName) {
				this.$message.warning('请先完善上一个配置');
				return false;
			}
			this.levels.push({ id: '', levelName: '' });
		},
		saveConfig(level, i) {
			if (level.levelName) {
				coreConfiglSave({ id: level.id, levelName: level.levelName }).then((res) => {
					if (res._responseStatusCode == 0) {
						this.levels[i].id = res.id;
					} else {
						this.levels[i].levelName = this.levelName;
					}
				});
			}
		}
	},
	mounted() {
		this.getList();
	}
};
</script>

<style lang="scss">
.corehumanConfig {
	> p {
		float: left;
		strong:last-of-type {
			margin-left: 10px;
		}
	}
	> div {
		margin-top: -12px;
		float: right;
	}
	> ul {
		margin-top: 50px;
		width: 30%;
		> label {
			transform: translateY(18px);
			display: inline-block;
		}
		li {
			margin: 15px 0;
			padding-left: 80px;
			.el-input {
				width: 60%;
				margin-right: 10px;
				.el-input__inner {
					text-align: center;
				}
			}
		}
		li:first-of-type {
			margin-top: 0;
		}
	}
	> .el-button {
		margin-left: 80px;
	}
}
</style>

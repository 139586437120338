import { post } from "./api";
//核心人力模块-审批列表（管理员）
export const coreApprovalList = (params) => post('/v1/core/approval/list', { request: params });
//核心人力模块-等级列表接口
export const coreConfiglList = (params) => post('/v1/core/level/config/list', { request: params });
//核心人力模块-等级保存接口
export const coreConfiglSave = (params) => post('/v1/core/level/config/save', { request: params });
//核心人力模块-等级删除接口
export const coreConfiglDel = (params) => post('/v1/core/level/config/del', { request: params });
//核心人力模块-申报保存
export const coreDeclarelSave = (params) => post('/v1/core/declare/save', { request: params });
//核心人力模块-获取近两年审批通过的核心人力
export const getNearlyInfo = (params) => post('/v1/core/declare/get/nearly/info', { request: params });
//核心人力模块-获取选择用户的信息
export const coreGetUserInfo = (params) => post('/v1/core/get/user/info', { request: params });
//核心人力模块-核心人力报表
export const coreReportList = (params) => post('/v1/core/report/list', { request: params });
//核心人力模块-申报/调整/复查/报表详情
export const coreHumanDetail = (params) => post('/v1/core/declare/detail', { request: params });
//核心人力模块-员工端查询对应的列表
export const approvalHumanstaff = (params) => post('/v1/core/approval/list/staff', { request: params });
//核心人力模块-审批提交接口
export const coreApprovalSubmit = (params) => post('/v1/core/approval/submit', { request: params });
//核心人力模块-review列表
export const coreRivewList = (params) => post('/v1/core/review/list', { request: params });
//核心人力模块-获取前年通过的申报、调整数据
export const getcoreData = (params) => post('/v1/core/data/get', { request: params });
//核心人力模块-复查保存
export const coreReviewSave = (params) => post('/v1/core/review/save', { request: params });
//核心人力模块-调整保存
export const coreChangeSave = (params) => post('/v1/core/change/save', { request: params });
//核心人力模块-员工端查询列表对应的总数
export const approvalListCount = (params) => post('/v1/core/approval/list/count', { request: params });